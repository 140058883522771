<template>
  <div class="container">
    <!-- 模板消息列表 -->
    <div class="search" style="display:flex">
      <el-form ref="searchform" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.template_type" @change="Search" placeholder="模板类型"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.id" @change="Search" placeholder="模板编号"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="AddType">新建</el-button>
      </div>
    </div>

    <el-table border v-bind:data="list">
      <el-table-column label="推送状态码">
        <template v-slot="scope">
          <span>{{ scope.row.push_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="模板类型">
        <template v-slot="scope">
          <span>{{ scope.row.template_type==1?'邮件':scope.row.template_type==2?'文本消息':scope.row.template_type==3?'图文消息':'未知' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="场景值">
        <template v-slot="scope">
          <span>{{ scope.row.scene_type==1?'系统消息':scope.row.scene_type==2?'普通邮件':scope.row.scene_type==3?'活动消息':'未知' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="info" size="mini" @click="Info(scope.row)">详情</el-button>
          <el-button type="danger" size="mini" @click="DeleteTempMsg(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog title="消息类型" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="消息类型编号" prop="id" label-width="110px">
            <el-input :disabled="edit" v-model="form.id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="推送状态码" prop="push_code" label-width="110px">
            <el-input v-model="form.push_code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="模板类型" prop="template_type" label-width="110px">
          <el-select v-model="form.template_type" placeholder="">
            <el-option label="邮件" :value="1"></el-option>
            <el-option label="文本消息" :value="2"></el-option>
            <el-option label="图文消息" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场景值" prop="scene_type" label-width="110px">
          <el-select v-model="form.scene_type" placeholder="">
            <el-option label="系统消息" :value="1"></el-option>
            <el-option label="普通邮件" :value="2"></el-option>
            <el-option label="活动消息" :value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Validate(Eddconfirm)">{{title}}</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        template_type: '',
        id: '',
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      form: {
        id: '',
        push_code: '',
        template_type: '',
        scene_type: '',
      },
      rules: {
        id: [{ required: true, message: '请输入消息类型编号', trigger: 'blur' }],
        push_code: [{ required: true, message: '请输入推送状态码', trigger: 'blur' }],
        template_type: [{ required: true, message: '请输入模板类型', trigger: 'blur' }],
        scene_type: [{ required: true, message: '请输入场景值', trigger: 'blur' }],
      },
      title: '',
      edit: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search() {
      this.api.TemplateTypeList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    AddType () {
      this.title = '创建'
      this.dialogFormVisible = true
      this.form = {}
      this.edit = false
    },
    Info (item) {
      this.edit = true
      this.title = '修改'
      this.dialogFormVisible = true
      this.api.TemplateTypeDetail({
        id: item.id
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    Eddconfirm () {
      this.api.TemplateTypeUpdate({
        id: +this.form.id,
        push_code: +this.form.push_code,
        template_type: this.form.template_type,
        scene_type: this.form.scene_type,
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title + '成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    DeleteTempMsg(item) {
      this.$confirm('是否删除消息模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.TemplateTypeDelete({
          id: item.id,
        }).then(res => {
           if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () {
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
